import React from 'react'
import styled from 'styled-components'
import Img, { FluidObject } from 'gatsby-image'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql, Link } from 'gatsby'

import { backgroundColors, colors } from 'styles/colors'

import { HelmetFunc } from 'components/PageMetaData'
import { Layout } from 'layout/Layout'
import { getImageByImageName } from 'utils/getImageByImageName'
import { displayWidth } from 'styles/width'

const pageMetadata = {
    uk: { title: 'ClearLine', description: 'Студія дизайну інтер`єра' },
    ru: { title: 'ClearLine', description: 'Студия дизайна интерьера' },
    en: { title: 'ClearLine', description: 'Interior design studio' },
}

const CenterBlock = styled.div`
    height: calc(100% - 268px);
    display: flex;
    justify-content: center;
    gap: 5%;
    margin: 80px 20px 0;
`

const Image = styled(Img)<{ fluid: FluidObject }>`
    width: 100%;
    height: auto;
    color: transparent;
    align-self: flex-end;
    @media (min-width: ${displayWidth.desktop}) {
        color: ${backgroundColors.services};
    }
`
const LinkS = styled(Link)`
    display: flex;
    flex-direction: column;
    width: 25%;
    max-width: 300px;
    height: auto;
    text-decoration: none;
`
const SubTitle = styled.h3`
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.4px;
    color: ${colors.dark};
    margin-bottom: 32px;
`

const Projects = () => {
    const { t } = useTranslation()
    const data = useStaticQuery(graphql`
        query {
            allImageSharp {
                edges {
                    node {
                        fluid(srcSetBreakpoints: [400]) {
                            originalName
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    `)

    const imageFluid1 = getImageByImageName(
        data.allImageSharp,
        'fullPlann.webp'
    )

    return (
        <Layout>
            <>
                <HelmetFunc data={pageMetadata} />
                <CenterBlock>
                    <LinkS to="/houses">
                        <Image
                            fluid={imageFluid1.fluid}
                            loading="eager"
                            alt="collage image"
                            title="collage image"
                        />
                        <SubTitle>La</SubTitle>
                    </LinkS>
                    <LinkS to="/houses">
                        <Image
                            fluid={imageFluid1.fluid}
                            loading="eager"
                            alt="collage image"
                            title="collage image"
                        />
                        <SubTitle>La</SubTitle>
                    </LinkS>
                    <LinkS to="/houses">
                        <Image
                            fluid={imageFluid1.fluid}
                            loading="eager"
                            alt="collage image"
                            title="collage image"
                        />
                        <SubTitle>{t('houses')}</SubTitle>
                    </LinkS>
                </CenterBlock>
            </>
        </Layout>
    )
}
export default Projects
